<template>
  <router-view />
</template>

<script>
import marketingRevenueModule from '../../store/marketing-revenue'

export default {
  beforeCreate () {
    this.$store.registerModule('marketing-revenue', marketingRevenueModule)
  },
  destroyed () {
    this.$store.unregisterModule('marketing-revenue')
  }
}
</script>
