import {
  MARKETING_REVENUE_FETCH_REQUEST, MARKETING_REVENUE_FETCH_SUCCESS, MARKETING_REVENUE_FETCH_FAILURE
} from './types'

export default {
  [MARKETING_REVENUE_FETCH_REQUEST] (state) {
    state.single = {}
    state.loading = true
  },
  [MARKETING_REVENUE_FETCH_SUCCESS] (state, { data }) {
    state.single = data
    state.loading = false
  },
  [MARKETING_REVENUE_FETCH_FAILURE] (state) {
    state.loading = false
  },
}
