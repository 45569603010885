import {
  MARKETING_REVENUE_FETCH_REQUEST,
  MARKETING_REVENUE_FETCH_SUCCESS,
  MARKETING_REVENUE_FETCH_FAILURE
} from './types'

export default {
  fetchMarketingRevenue ({ commit }, { from, to }) {
    commit(MARKETING_REVENUE_FETCH_REQUEST)
    return window.axios.get(`/marketing-revenue`, { params: { from, to } }).then(response => {
      commit(MARKETING_REVENUE_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(MARKETING_REVENUE_FETCH_FAILURE, error)
      throw error
    })
  },
}
